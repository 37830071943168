<app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>
<ngx-scrolltop></ngx-scrolltop>

<!-- Whatsapp Floating Button-->
<section id=" whatsapp" class=" whatsapp">
	<link rel="stylesheet"
		  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css" />

	<style>

		/* Floating button CSS */
		.appfloat {
			position: fixed;
            width: 43px;
            height: 43px;
            bottom: 18px;
            right: 18px;
            background-color: #25D366;
            color: #fff;
            border-radius: 50px;
            text-align: center;
            font-size: 24px;
            z-index: 100;
            box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
		}

        .appfloat.to-top {
            bottom: 80px;
        }

		.whatsapp a:hover {
			background-color: #21c25d;
			color: #fff;
		}

		.fab-icon {
			margin-top: 10px;
		}

		/* Tooltip CSS */
		.tooltip {
			position: absolute;
			bottom: 50%;
			right: 130%; /* Position to the left of the button */
			transform: translateY(50%);
			background-color: #fff;
			color: black;
			text-align: center;
			border-radius: 4px;
			padding: 10px 10px; /* Adjust padding for a wider and shorter tooltip */
			z-index: 101;
			width: 210px;

			/* Visibility */
			visibility: visible;
			opacity: 1;

			/* Fade-in effect */
			transition: opacity 0.6s;

			box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
		}

		/* Arrow for tooltip */
		.tooltip::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 100%; /* Position arrow on the right side of the tooltip */
			margin-top: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent transparent transparent #fff;
		}
	</style>

	<a href="https://wa.me/97338870070" id="whatsapp" class="appfloat" target="_blank" [ngClass]="{
        'to-top': moveWhatsAppButton
    }">
		<i class="fab fa-whatsapp fab-icon"></i>
		<span class="tooltip">{{ 'Need Help?' | translate }} <b>{{ 'Chat With Us!' | translate }}</b></span>
	</a>
	
</section>