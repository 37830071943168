<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'All News' | translate }}</h2>
            <ul>
                <li><a routerLink="/">{{ 'Home' | translate }} </a></li>
                <li class="active">{{ 'All News' | translate }}</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'All News' | translate }}</h2>
        </div>

        <app-loader *ngIf="!newsData"></app-loader>

        <div class="row" *ngIf="newsData">
            <div class="col-lg-4 col-md-6" *ngFor="let news of newsData | paginate: { itemsPerPage: 9, currentPage: page }">
                <div class="single-blog">
                    <div class="blog-img" [style.background-image]="getBackgroundImageUrl(news.value[0])">
                        <a routerLink="/news-details/{{ news.key }}"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>{{ news.value[2] }}</li>
                            <li class="d-none"><a routerLink="/news">By Admin</a></li>
                        </ul>
                        <a routerLink="/news-details/{{ news.key }}"><h3 class="blog-title" [innerHTML]="textService.removeHTMLTags(news.value[1])"></h3></a>
                        <p [innerHTML]="textService.getSummary(news.value[3], 200)"></p>
                        <a routerLink="/news-details/{{ news.key }}" class="line-bnt">{{ 'Read More' | translate }}</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 d-flex justify-content-center">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
            </div>

            <div class="col-lg-12 d-none">
                <div class="page-navigation-area">
                    <nav aria-label="Page navigation example text-center">
                        <ul class="pagination">
                            <li class="page-item"><a class="page-link page-links" routerLink="/blog"><i class='bx bx-chevrons-left'></i></a></li>
                            <li class="page-item active"><a class="page-link" routerLink="/blog">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog"><i class='bx bx-chevrons-right'></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>