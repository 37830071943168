import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TextService } from 'src/app/services/text.service';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
  newsData: any = null;

  private key: number | null = null;

  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    public textService: TextService,
    private router: Router,
    private meta: Meta,
    private _translate: TranslateService,
    private title: Title) {
    this.meta.updateTag({name: 'description', content: 'Amthal Group is the leading cloud ERP solutions provider in the Kingdom of Bahrain and other GCC countries.'});

    this._translate.get('Amthal Group - Leading Business Solutions Provider in Middle East').subscribe({
      next: (res: string) => {
        this.title.setTitle(res);
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.key = params['key'];
    });

    this.http.get('https://system.al-amthal.com/optimumapiv3/Noticeboard/CMSData?menuID=61&pageID=70&compID=16').subscribe({
        next: (val: any) => {
            this.newsData = val;

            this.title.setTitle(this.getNews().value[1]);
        }
    });
  }

  navigateToSelf(): void {
    const randomValue = Math.random(); // Generate a random value
    this.router.navigate(['/component', randomValue]);
  }

  getNews() {
    return this.newsData.find((n: any) => n.key == this.key);
  }

  getOtherPosts() {
    return this.newsData.filter((n: any) => n.key != this.key).slice(0, 4);
  }

}
