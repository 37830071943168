import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OtpModalComponent } from '../../otp-modal/otp-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public PhoneNumberFormat = PhoneNumberFormat;
  public CountryISO = CountryISO;
  public SearchCountryField = SearchCountryField;
  public contactForm: FormGroup;
  public apiLoaded: Observable<boolean>;
  public center: google.maps.LatLngLiteral = {lat: 26.229497016, lng: 50.546485817};
  public zoom = 19;
  public markerOptions: google.maps.MarkerOptions = {draggable: false};
  public lang: string;
  public isLoading: boolean = false;
  public markerPositions: google.maps.LatLngLiteral[] = [
      {lat: 26.229497016, lng: 50.546485817}
  ];

  constructor(public http: HttpClient,
    public modalService: NgbModal,
    private toastr:ToastrService,
    private formBuilder: FormBuilder,
    public recaptchaV3Service: ReCaptchaV3Service,
    private meta: Meta,
    private _translate: TranslateService,
    private title: Title) {
    this.meta.removeTag('description');

    this._translate.get('Contact Al Amthal Group for any inquiries or product support. We are here to assist you!').subscribe({
      next: (res: string) => {
				this.meta.updateTag({name: 'description', content: res});
      }
    });

    this._translate.get('Contact Us').subscribe({
      next: (res: string) => {
        this.title.setTitle(res);
      }
    });
      
    this.lang = localStorage.getItem('language');

    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, this.customEmailValidator]],
      title: ['', Validators.required],
      phone_number: ['',[ Validators.required, this.customNumberValidator]],
      comp_name: ['', Validators.required],
      msg_subject: ['', Validators.required],
      message: ['', Validators.required],
    });

    this.apiLoaded = http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC66wNmG9uVVFq2pfnNanWnuPLrrpuyePg', 'callback')
            .pipe(
            map(() => true),
            catchError(() => of(false)),
            );
  }

  customEmailValidator(control: { value: string }): { [key: string]: boolean } | null {
    // Custom email validation
    if (control.value && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  customNumberValidator(control: { value: string }): { [key: string]: boolean } | null {
    // Custom number validation
    if (control.value && !/^\d+$/.test(control.value)) {
      return { invalidNumber: true };
    }
    return null;
  }

  getFormValidator(id: string) {
    return this.contactForm.get(id).errors && this.contactForm.get(id).touched; 
  }

  ngOnInit(): void {

  }



  onSubmit() { 
    let isSubmittedCorrect: boolean = true;
    Object.keys(this.contactForm.controls).forEach(key => {
      const control = this.contactForm.get(key);
      if (control.value === '' || control.value === null) {
        isSubmittedCorrect = false
      }
    });
    if (isSubmittedCorrect) {
      this.isLoading = true;
      let payload: any = {
        CompanyName: this.contactForm.value.comp_name,
        ContactEmail: this.contactForm.value.email,
        ContactMobile: this.contactForm.value.phone_number.e164Number,
        ContactName: this.contactForm.value.name,
        ContactTitle: this.contactForm.value.title,
        Message: this.contactForm.value.message,
        PriceID: "0",
        Subject: this.contactForm.value.msg_subject,
        country: 'N/A Contact Form',
        numberOfUsers: '0',
        ReCaptcha: ''
      }
      // const formData = new FormData();
      // formData.append('CompanyName', this.contactForm.value.comp_name);
      // formData.append('ContactEmail', this.contactForm.value.email );
      // formData.append('ContactMobile', this.contactForm.value.phone_number.e164Number );
      // formData.append('ContactName', this.contactForm.value.name );
      // formData.append('ContactTitle', this.contactForm.value.title);
      // formData.append('Message', this.contactForm.value.message );
      // formData.append('PriceID', '0' );
      // formData.append('Subject', this.contactForm.value.msg_subject );
      // formData.append('country', 'N/A Contact Form' );
      // formData.append('numberOfUsers', '0' );      
      this.recaptchaV3Service.execute('importantAction').subscribe((token: any) => {
        if(token) {
          payload.ReCaptcha = token;
          //formData.append('ReCaptcha', token );
          this.http.post<any>('https://system.al-amthal.com/optimumapiv3/Website/Request/Contactus',payload).subscribe({
            next: (val: any) => {
              if(val.succeeded) {
                let ref = val.data;
                let modalRef = this.modalService.open(OtpModalComponent,{windowClass: 'otp-modal', centered: true, backdrop:true, size: 'md'});
                modalRef.componentInstance.ref = ref;
                modalRef.result.then(result => {
                  if(result) {
                    if(this.lang == 'en') {
                      this.toastr.success("We've received your message. Our team will be in touch soon. Thank you!", 'Thank You for Contacting Us!' );
                    } else {
                      this.toastr.success("لقد تلقينا رسالتك. فريقنا سوف يكون على اتصال قريبا. شكرًا لك!", 'أشكركم على الاتصال بنا!' );
                    }
                    this.isLoading = false;
                    this.contactForm.reset();
                  } else {
                    if(this.lang == 'en') {
                      this.toastr.warning('OTP Verification Process Cancelled', 'Warning: Process Incomplete' );
                    } else {
                      this.toastr.warning('تم إلغاء عملية التحقق من OTP', 'تحذير: العملية غير مكتملة' );
                    } 
                    this.isLoading = false;
                  }                 
                });
              } else {
                if(this.lang == 'en') {
                  this.toastr.error("An error occurred while submitting your request", 'Error Occured' );
                } else {
                  this.toastr.error("حدث خطأ أثناء تقديم طلبك", 'حدث خطأ' );
                } 
                this.isLoading = false;
              }
            },
            error: (err: any) => {
              if(this.lang == 'en') {
                this.toastr.error("An error occurred while submitting your request", 'Server Timed Out' );

              } else {
                this.toastr.error("حدث خطأ أثناء تقديم طلبك", 'انتهت مهلة الخادم' );

              } 
              this.isLoading = false;
            }
          });
        } else {
          if(this.lang == 'en') {
            this.toastr.error("Re-Captcha Verification Timeout", 'Re-Captcha Timed Out' );

          } else {
            this.toastr.error("انتهت مهلة التحقق من إعادة التحقق", 'انتهت مهلة إعادة التحقق' );

          } 
          this.isLoading = false;
        }
      });
    } else {
      Object.keys(this.contactForm.controls).forEach(key => {
        const control = this.contactForm.get(key);
        if (control.value === '' || control.value === null) {
          control.markAsTouched();
        }
      });
    }
  
  }


}
