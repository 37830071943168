<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'Online Marketing' | translate }}</h2>
            <ul>
                <li><a routerLink="/">{{ 'Home' | translate }} </a></li>
                <li class="active">{{ 'Online Marketing' | translate }}</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <p>{{'With Al-Amthal, runing digital campaigns to promote your company, products or services are easier than ever. All you have to do is subscribe to the most suitable package for your business on any of Al-Amthal\'s apps like Mallats or Aqaraats and leave the rest to us. Our expert team will design and compose the messages of your choice.' | translate }}</p>
                <p>{{'The following are types of digital campaigns available:' | translate}}</p>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="feature-img">
                    <img class="rounded" src="https://system.al-amthal.com/AmthalV12/Upload/B2C/Emails.jpg" alt="feature" />
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>{{'Email Campaigns' | translate}}</h2>
                        <p>{{'Through a well experienced and qualified team, Al-Amthal provides the best email templates for your products and services. These templates can be saved and resent again using a scheduler directly from the system. This way of marketing is becoming increasingly popular.' | translate}}</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> {{'Check for broken pages, links, and problems with basic html tags.' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Detect complex issues related to international SEO, crawlability, and schema optimization.' | translate}}</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>{{'SMS' | translate}}</h2>
                        <p>{{'SMS Marketing is the need of the hour. A short and straight to point message with a URL is delivered directly to consumer\'s mobile devices. Following are the advantages:' | translate}}</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> {{'Direct, immediate channel' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Use shortcodes to simplify response and build your database.' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Support and integrate with other channels.' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Learn more about your customers.' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Increase customer engagement.' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Enables you to monitor, track and improve' | translate}}</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="feature-img">
                    <img class="rounded" src="https://system.al-amthal.com/AmthalV12/Upload/B2C/SMS.jpg" alt="feature" />
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="feature-img">
                    <img class="rounded" src="https://system.al-amthal.com/AmthalV12/Upload/B2C/notification.jpg" alt="feature" />
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>{{'Push Notifications' | translate}}</h2>
                        <p>{{'Al-Amthal\'s marketplace apps like Mallats & Aqaraats have thousands of users. Push Notifications on apps are delivered directly to customer phones enabling direct engagement with the users. You just have to subscribe for our packages and choose your messages. Leave the rest to our talented experts.' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>