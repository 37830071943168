import {  ChangeDetectorRef, Component, HostListener, afterRender } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy, ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import { Meta, Title } from '@angular/platform-browser';
import { TrackerService } from './services/tracker.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;

    constructor( private router: Router,
        private meta: Meta,
        private _translate: TranslateService,
        private title: Title,
        private tracker: TrackerService,
        private activatedRoute: ActivatedRoute,
        private viewportScroller: ViewportScroller,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        afterRender(() => {
            _translate.setDefaultLang('en');

            if(window.location.pathname == '/en' || window.location.pathname == '/en/') {
                localStorage.setItem('language', 'en');
            } else if(window.location.pathname == '/ar' || window.location.pathname == '/ar/') {
                localStorage.setItem('language', 'ar');
            }

            let language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

            if(!language) {
                language = navigator.language.split('-')[0];

                if(language == 'en' || language == 'ar') {
                    localStorage.setItem('language', language);
                } else {
                    localStorage.setItem('language', 'en');
                }
            }

            _translate.use(language);

            if(language == 'ar') {
                document.documentElement.dir = "rtl";
            } else {
                document.documentElement.dir = "ltr";
            }

            document.documentElement.lang = language;
        });

        this.meta.addTags([
            {name: 'og:image', content: 'assets/images/AmthalLogo.jpg'}, 
            {name: 'description', content: 'Amthal Group is the leading cloud ERP solutions provider in the Kingdom of Bahrain and other GCC countries.'},
            {name: 'keywords', content: "Al-Amthal,amthal group, software company Bahrain, ERP Bahrain,mycrm,software company in Oman,software company amthal,licensed functional product,software company in qatar,الأمثل,software company dubai,Leading Software Company, customised software solution, Cloud software, licensed software, E-commerce solution,cloud solutions,on-premise applications, Financial solutions, digital marketing, HRM's solutions, Software company, software solutions, distinguised software provider, ERP software, intergrated business solutions, innovtive software products, erp software, IT and software company, nearby software company. Amthal Solutions, Amthal Products."}
        ]);
    }

    ngOnInit(){
        afterRender(() => {
            this.recallJsFuntions();

            window.addEventListener('hashchange', () => {
                this.tracker.send(window.location.href);
            }, false);

            this.activatedRoute.fragment.subscribe(fragment => {
                if (fragment) {
                // Wait for the next cycle of the event loop
                setTimeout(() => this.scrollToFragment(fragment), 0);
                }
            });
        });
    }

    private scrollToFragment(fragment: string): void {
        if(fragment.includes('/')) {
            fragment = fragment.split('/')[0];
        }

        this.changeDetectorRef.detectChanges(); // Detect changes in the component
        const element = document.querySelector(`#${fragment}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        } else {
            // If the element is not found, it might not be rendered yet.
            // You can either retry after a delay or handle the case appropriately.
            setTimeout(() => {
                const elementRetry = document.querySelector(`#${fragment}`);
                if (elementRetry) {
                elementRetry.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                }
            }, 100); // Adjust the timeout as needed
        }
      }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            if(event instanceof NavigationEnd) {
                this.tracker.send(window.location.href);
            }

            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    public moveWhatsAppButton: boolean = false;

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        const verticalPosition = window.scrollY;

        if(verticalPosition >= 710) {
            this.moveWhatsAppButton = true;
        } else {
            this.moveWhatsAppButton = false;
        }
    }

    

}