<div class="modal-header">
  <h6 class="modal-title">{{'Announcement' | translate}}</h6>
  <button type="button" class="btn-close m-0" aria-label="Close" (click)="popUpModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body blog-details-area p-0">
  <div class="article-image">
    <img [src]="modalData.img" alt="announcement Image" loading="eager">
  </div>
  <div class="article-content px-4 py-3"  *ngIf="modalData.title != '' &&  modalData.subtitle != ''">
      <h5 class="mb-2"  *ngIf="modalData.title != ''" >{{ modalData.title | translate}}</h5>
      <div *ngIf="modalData.subtitle != ''">{{modalData.subtitle | translate}}</div>
  </div>
</div>
<div class="modal-footer justify-content-between align-items-center" >
  <div class="d-flex align-items-center dnt-show">
    <input type="checkbox" id="showAgain" style="margin: 0px 8px; cursor: pointer;" (change)="emitShowAgain($event, modalData.id)">
    <label for="showAgain" style="cursor: pointer;" translate>Do not show again</label>
  </div>
  <button type="button" class="box-btn" aria-label="Close" (click)="popUpModal.dismiss('Cross click')">
    <span translate>Close</span>
  </button>
  <!-- <a [href]="environment.appUrl + modalData.file" target="_blank" class="d-flex align-items-center" style="font-weight: bold"   *ngIf="modalData.file != ''">
    <i style="font-size: 20px" class="bi bi-file-earmark-pdf"></i>
    <span style="font-size: 15px" translate>&nbsp;View PDF</span>
  </a> -->
</div>
