
<section>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <h2>{{ 'About Us' | translate }}</h2>
                <ul>
                    <li><a routerLink="/">{{ 'Home' | translate }} </a></li>
                    <li class="active">{{ 'About Us' | translate }}</li>
                </ul>
            </div>
        </div>
    
        <div class="page-shape">
            <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
            <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
            <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
            <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
            <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
        </div>
    </div>
    
    <section class="home-company-area d-none">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="company-content">
                        <div class="company-tittle">
                            <span>Our Company</span>
                            <h2>Innovative It Helping Service Allover the World</h2>
                            <p>It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more or less normal distribution of letters, as opposed to using Content here,content here normal distribution looking at its.</p>
                            <p>It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more or less normal distribution of letters, as opposed to using Content here,content here normal distribution looking at its.</p>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-6 col-md-12">
                    <div class="company-img">
                        <img src="assets/images/company/company-img.jpg" alt="company" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="technology-area ptb-100">
        <div class="container">
            <div class="row align-items-center choose-c">
                <div class="col-lg-6">
                    <div class="technology-content">
                        <h2>{{ 'Corporate Profile' | translate }}</h2>
                        <p>{{ 'Since its foundation in the year 2000 Al-Amthal Group of companies has developed into a distinguished software and IT service provider that has been working together with its clients to live up to the challenges of the fast-evolving world of information technology. Al-Amthal Group has come a long way since its start by a small team of only four ambitious specialists with a strive to improve business management beyond the ordinary, living up to the highest standards.' | translate }}</p>
                        <p>{{ 'Today clients from a wide range of industries rely on our ERP solutions, which make a real impact on the management of their businesses.' | translate }}</p>
                        <a href="{{textService.getCooperateProfileLink()}}" target="_blank" class="box-btn fs-4 link">
                            <i class='bx bxs-file-pdf'></i>
                        </a>
                    </div>
                </div>
    
                <div class="col-lg-6">
                    <div class="choose-img">
                        <img src="https://i.ytimg.com/vi/jJ1RF-HQv5o/maxresdefault.jpg" alt="choose" />
    
                        <div class="technology-video">
                            <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                                <i class='bx bx-play' ></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- Video Popup -->
    <div class="video-popup" *ngIf="isOpen">
        <div class="popup-inner">
            <iframe src="https://www.youtube.com/embed/jJ1RF-HQv5o?si=FuREdH4AD5xTmfYl" title="Corporate Profile of Al-Amthal Group" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <button (click)="closePopup()" type="button" class="close-btn">
                <i class='bx bx-x'></i>
            </button>
        </div>
    </div>
    
    <section class="feature-area ptb-100 bg-color">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                    <div class="feature-img">
                        <img src="https://cdn2.mallats.com/AmthalGroup/img/about-us.png?v=15" alt="feature" />
                    </div>
                </div>
    
                <div class="col-lg-9">
                    <div class="contnet">
                        <div class="feature-tittle">
                            <span class="d-none">Features</span>
                            <h3>{{ 'What We Do' | translate }}</h3>
                            <p>{{ 'We help our clients’ enterprises to function more efficiently and effectively by giving them better control over the entire spectrum of their operations through our innovative business and finance management software systems, designed to meet their needs in an evolving digital marketplace.' | translate }}</p>
                            <p>{{ 'We specialize in delivering innovative digital marketing solutions using state-of-the-art networking and communication technologies to enhance the productivity of our clients’ processes, boost their visibility in the market, and augment the overall profitability of their businesses.' | translate }}</p>
                        </div>
                        <div class="feature-tittle">
                            <span class="d-none">Features</span>
                            <h3>{{ 'Our Vision' | translate }}</h3>
                            <p>{{ 'Our vision is to be the leading IT services provider of innovative and reliable IT solutions that enable businesses to succeed in the digital era using cutting-edge technology and best practices to deliver high-quality products and services. We are committed to helping our customers grow their businesses and achieve their vision.' | translate }}</p>
                        </div>
                        <div class="feature-tittle">
                            <span class="d-none">Features</span>
                            <h3>{{ 'Our Mission' | translate }}</h3>
                            <p>{{ 'Our mission is to provide high quality, reliable and innovative IT solutions and services to customers through Our e-commerce solutions, Our software as a service solutions (SaaS), Our licensed software solutions (On Premises), and Our financial technology solutions (FinTech). The goal is to provide added value to our customers by providing them with the latest technologies, best practices and high-quality services, and for our employees and partners, we strive to promote a culture of excellence, cooperation and creativity. We believe that by leveraging our skills and experience, we can help our clients achieve their goals and grow their business in the digital era.' | translate }}</p>
                        </div>
                        <ul class="d-none">
                            <li><i class="flaticon-correct"></i> Freelancing Traning Course</li>
                            <li><i class="flaticon-correct"></i> Technological Consultation</li>
                            <li><i class="flaticon-correct"></i> Monthly Paid Workspace</li>
                            <li><i class="flaticon-correct"></i> IT Learning Institute</li>
                            <li><i class="flaticon-correct"></i> Digital Marketing</li>
                        </ul>
                        <a routerLink="/contact" class="box-btn d-none">Lets Talk!</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="technology-area ptb-100">
        <div class="container">
            <div class="row align-items-center choose-c technology-content">
                <h2 class="text-center">{{ 'Quality Policy' | translate }}</h2>
                <h3>{{ 'Al-Amthal is committed to provide:' | translate }}</h3>
                <p><b>"{{ 'Total Integrated Business Solutions' | translate }}"</b> {{ 'by optimizing business processes through the development and implementation of innovative software products, state-of-the-art hardware solutions and professional consultancy services that comply with applicable requirements.' | translate }}</p>
                <p><b>"{{ 'Total Customer Satisfaction' | translate }}"</b> {{ 'through subject matter expertise, commitment to our customer requirements and dedicated customer services.' | translate }}</p>
                <p><b>"{{ 'A Professional and Motivating Work Environment' | translate }}"</b> {{ 'by inspiring our people through fostering their talent and challenging their capabilities to create opportunities for growth.' | translate }}</p>
                <p><b>"{{ 'Continuous Improvement' | translate }}"</b> {{ 'of our processes, services, products, people & Quality Management System through constant review of enhancement opportunities. We will always strive to create a prefect product in order to achieve excellence based on international standards.' | translate }}</p>
            </div>
        </div>
    </section>    
</section>