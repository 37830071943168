<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="https://cdn2.mallats.com/cdn-cgi/image/width=45,format=webp/AmthalGroup/img/Amthal-Logo.svg" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+97317297227" class="d-flex align-items-center"><i class="bx bxs-phone-call"></i> <div class="phone-number">+973 17297227</div></a></li>
                        <li><a href="mailto:amthaladmin@al-amthal.com"><i class="bx bxs-envelope"></i> amthaladmin&#64;al-amthal.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li><a href="https://www.facebook.com/Amthal-Group-218394561513052/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com/amthalgroup/" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="nav-x-logo"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg.nav-x-logo{fill:#0080c4 !important;font-size: 14px;}</style><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                        </a></li>
                        <li><a href="https://instagram.com/amthalgroup/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li class="d-none"><a href="#" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                        <li><a href="https://www.youtube.com/@amthalgroups" target="_blank"> <i class="bx bxl-youtube"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/amthalgroup" target="_blank"> <i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div
        class="main-nav"
        [ngClass]="{'sticky': isSticky}"
    >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="https://cdn2.mallats.com/AmthalGroup/new/logo.svg" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left" id="nav-list">
                        <li class="nav-item" [ngClass]="{'active': currentSection == 'home' }" (click)="currentSection = 'home'">
                            <a routerLink="/"  class="nav-link" >{{ 'Home' | translate }}</a>
                        </li>
                        <li class="nav-item" [ngClass]="{'active': currentSection == 'about' }"  (click)="currentSection = 'about'">
                            <a routerLink="/about" class="nav-link" >{{ 'About' | translate }}</a>
                        </li>

                        <li class="nav-item" [ngClass]="{'active': currentSection == 'divisions' }"  (click)="currentSection = 'divisions'" >
                            <a routerLink="/" fragment="divisions"  class="nav-link">{{'Divisions' | translate }}</a>
                        </li>

                        <li class="nav-item"  [ngClass]="{'active': currentSection == 'partners' }"  (click)="currentSection = 'partners'">
                            <a routerLink="/"  fragment="partners" class="nav-link">{{'Partners' | translate }}</a>
                        </li>
                        <li class="nav-item" [ngClass]="{'active': currentSection == 'products' }"  (click)="currentSection = 'products'" >
                            <a routerLink="/" fragment="products" class="nav-link" >{{'Products' | translate }}</a> <!--outerLinkActive="active" [routerLinkActiveOptions]="{ matrixParams: 'subset', queryParams: 'exact', paths: 'exact' , fragment: 'exact'  }"-->
                        </li>
                        <li class="nav-item" [ngClass]="{'active': currentSection == 'team', }"  (click)="currentSection = 'team'" >
                            <a routerLink="/" fragment="team" class="nav-link" >{{'Team' | translate }}</a> <!--routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"-->
                        </li>                 

                        <!-- <li class="nav-item d-none">
                            <a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: 'true'}">FAQ</a>
                        </li>

                        <li class="nav-item d-none">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Case Studies</a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: 'true'}">Case Studies</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: 'true'}">Case Studies Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item d-none">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item d-none">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Error 404</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"  [ngClass]="{'active': currentSection == 'contact' }"    (click)="currentSection = 'contact-us'">
                            <a routerLink="/contact" class="nav-link">{{ 'Contact' | translate }} </a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                                <i class='bx bx-globe me-1'></i>
                                <span>{{ 'Language' | translate }}</span>
                            </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a class="nav-link d-flex align-items-center" (click)="useLanguage('en')">
                                        <img class="me-2" width="20px" src="https://cdn2.mallats.com/AmthalGroup/img/flags/united-states.png" />
                                        <span>English</span>
                                    </a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link d-flex align-items-center" (click)="useLanguage('ar')">
                                        <img class="me-2" width="20px" src="https://cdn2.mallats.com/AmthalGroup/img/flags/saudi-arabia.png" />
                                        <span>عـــــــــــربي</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="nav-right d-none">
                    <form>
                        <div class="input-group">
                            <input type="text" class="form-control search" placeholder="Search..." />
                        </div>

                        <button type="submit">
                            <i class="bx bx-search"></i>
                        </button>
                    </form>
                </div>
                <div class="nav-btn">
                    <a href="https://portal.al-amthal.com/en/Account/Login.aspx" target="_blank" class="box-btn">{{ 'Customer Portal' | translate }}</a>
                </div>
            </nav>
        </div>
    </div>
</div>