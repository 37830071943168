import { Injectable } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PdfViewerOptionsModalComponent } from '../components/pdf-viewer-options-modal/pdf-viewer-options-modal.component';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  constructor(private offcanvasService: NgbOffcanvas,
    private _translate: TranslateService) { }

  getSummary(text: string, maxLength: number): string {
    // Sanitize & Remove HTML tags
    const plainText = this.removeHTMLTags(text);
    
    // Truncate the text to the desired length
    if (plainText.length > maxLength) {
      return plainText.substring(0, maxLength) + '...';
    } else {
      return plainText;
    }
  }

  removeHTMLTags(text: string) {
    return text.replace(/<[^>]*>/g, '');
  }

  encode(text: string) {
    return encodeURIComponent(text);
  }

  getCooperateProfileLink() {
    if(localStorage.getItem('language') == 'en') {
      return 'https://www.amthalgroup.com/docs/CorporateProfile_EN.pdf';
    } else if(localStorage.getItem('language') == 'ar') {
      return 'https://www.amthalgroup.com/docs/AMTHAL CORPORATE PROFILE_AR_2024.pdf';
    }

    return 'https://www.amthalgroup.com/docs/CorporateProfile_EN.pdf';
  }

  openCorporateProfile() {
    /* this._translate.get('Corporate Profile').subscribe({
      next: (res: string) => {
        let modalRef = this.offcanvasService.open(PdfViewerOptionsModalComponent, { position: 'bottom', panelClass: 'offcanvas-fullscreen' });
        modalRef.componentInstance.ref = {
            title: res,
            link: this.getCooperateProfileLink()
        };
      }
    }); */
  }
  
}
