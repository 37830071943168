<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'News Details' | translate }}</h2>
            <ul>
                <li><a routerLink="/">{{ 'Home' | translate }} </a></li>
                <li class="active">{{ 'News Details' | translate }}</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <app-loader *ngIf="!newsData"></app-loader>
        <div class="row" *ngIf="newsData">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="{{ 'https://system.al-amthal.com/amthalv12/' + getNews().value[0] }}" alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On:</span>&nbsp;{{ getNews().value[2] }}</li>
                            </ul>
                        </div>
                        <h3 [innerHTML]="textService.removeHTMLTags(getNews().value[1])"></h3>
                        <div [innerHTML]="getNews().value[3]"></div>
                    </div>

                    <div class="article-footer d-none">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a href="#" target="_blank">Share</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation d-none">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog-details"><i class='bx bx-left-arrow-alt'></i> Prev Post</a>
                            </div>

                            <div class="nav-next">
                                <a routerLink="/blog-details">Next Post <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="comments-area d-none">
                        <h3 class="comments-title">2 Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment">
                                <div class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/images/blog-details/comment-img-1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/blog-details"><span>April  24, 2023 at 10:59 am</span></a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </div>

                                <ol class="children">
                                    <li class="comment">
                                        <div class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/images/blog-details/comment-img-2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>
    
                                                <div class="comment-metadata">
                                                    <a routerLink="/blog-details"><span>April  24, 2023 at 10:59 am</span></a>
                                                </div>
                                            </footer>
    
                                            <div class="comment-content">
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim</p>
                                            </div>
    
                                            <div class="reply">
                                                <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                            </div>
                                        </div>
                                    </li>
                                </ol>
                            </li>

                            <li class="comment">
                                <div class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/images/blog-details/comment-img-3.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/blog-details"><span>April  24, 2023 at 10:59 am</span></a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </div>
                            </li>
                        </ol>

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked 
                                    <span class="required">*</span>
                                </p>

                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" name="author" required="required">
                                </p>

                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" name="email" required="required">
                                </p>

                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" name="url">
                                </p>

                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                                </p>

                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <div class="widget widget_search d-none">
                        <h3 class="widget-title">Search Now</h3>

                        <div class="post-wrap">
                            <form class="search-form">
                                <label>
                                    <span class="screen-reader-text">Search for:</span>
                                    <input type="search" class="search-field" placeholder="Search...">
                                </label>

                                <button type="submit"><i class='bx bx-search'></i></button>
                            </form>
                        </div>
                    </div>

                    <section class="widget widget-peru-posts-thumb">
                        <h3 class="widget-title">{{ 'Other News' | translate }}</h3>

                        <div class="post-wrap">
                            <article class="item" *ngFor="let post of getOtherPosts()">
                                <a routerLink="/news-details/{{ post.key }}" class="thumb"><span class="fullimage cover" role="img" [style.background-image]="'url(' + 'https://system.al-amthal.com/amthalv12/' + textService.encode(post.value[0]) + ')'"></span></a>
                                <div class="info">
                                    <time datetime="2023-06-30">{{ post.value[2] }}</time>
                                    <h4 class="title usmall"><a routerLink="/news-details/{{ post.key }}" [innerHTML]="textService.getSummary(post.value[3], 85)"></a></h4>
                                </div>
                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <section class="widget widget_categories d-none">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/blog">January <span>2023</span></a></li>
                                <li><a routerLink="/blog">February <span>2023</span></a></li>
                                <li><a routerLink="/blog">March <span>2023</span></a></li>
                                <li><a routerLink="/blog">April <span>2023</span></a></li>
                                <li><a routerLink="/blog">May <span>2023</span></a></li>
                                <li><a routerLink="/blog">June <span>2023</span></a></li>
                            </ul>
                        </div>
                    </section>
                    
                    <section class="widget widget_categories d-none">
                        <h3 class="widget-title">Categories</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/blog">Business <span>(10)</span></a></li>
                                <li><a routerLink="/blog">Privacy <span>(20)</span></a></li>
                                <li><a routerLink="/blog">Technology <span>(10)</span></a></li>
                                <li><a routerLink="/blog">Tips <span>(12)</span></a></li>
                                <li><a routerLink="/blog">Uncategorized <span>(16)</span></a></li>
                                <li><a routerLink="/blog">Aiva <span>(17)</span></a></li>
                            </ul>
                        </div>
                    </section>

                    <section class="widget widget_meta d-none">
                        <h3 class="widget-title">Meta</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/sign-in">Log in</a></li>
                                <li><a routerLink="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                <li><a routerLink="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                <li><a routerLink="/">WordPress.org</a></li>
                            </ul>
                        </div>
                    </section>

                    <section class="widget widget_tag_cloud d-none">
                        <h3 class="widget-title">Tags</h3>

                        <div class="post-wrap">
                            <div class="tagcloud">
                                <a routerLink="/">Business (3)</a>
                                <a routerLink="/">Privacy (3)</a>
                                <a routerLink="/">Technology (2)</a>
                                <a routerLink="/">Tips (2)</a>
                                <a routerLink="/">Uncategorized (1)</a>
                                <a routerLink="/">Aiva</a>
                            </div>
                        </div>
                    </section>       
                </aside>
            </div>
        </div>
    </div>
</section>