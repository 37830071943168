<section class="slider-area-2"  id="home">
    <div class="home-slider">
        <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>{{ 'Striving for Excellence to Meet Client Expectations' | translate }}</h1>
                                            <p>{{ 'We are committed to continuous improvement across our processes, services, products, people, and Quality Management System on international standards.' | translate }}</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn" title="{{ 'Let’s Talk!' | translate }}">{{ 'Let’s Talk!' | translate }}</a>
                                                <a routerLink="/about" class="box-btn border-btn" title="{{ 'Know More' | translate }}">{{ 'Know More' | translate }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>{{ 'Quality and Efficiency Are Our Goals' | translate }}</h1>
                                            <p>{{ 'We believe that by leveraging our skills and experience, we can help our clients achieve their goals and grow their business in the digital era.' | translate }}</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn" title="{{ 'Let’s Talk!' | translate }}">{{ 'Let’s Talk!' | translate }}</a>
                                                <a routerLink="/about" class="box-btn border-btn" title="{{ 'Know More' | translate }}">{{ 'Know More' | translate }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-3">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>{{ 'Making Real-Life Experiences With IT' | translate }}</h1>
                                            <p>{{ 'Dedicated to delivering integrated business solutions that optimize processes through innovative software products.' | translate }}</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn" title="{{ 'Let’s Talk!' | translate }}">{{ 'Let’s Talk!' | translate }}</a>
                                                <a routerLink="/about" class="box-btn border-btn" title="{{ 'Know More' | translate }}">{{ 'Know More' | translate }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>

<section class="clients-section bg-color">
    <div class="client-slider">
        <owl-carousel-o [options]="clientsSlider" class="client">
            <ng-template carouselSlide *ngFor="let client of clients">
                <div class="client-img">
                    <!-- TODO: title="{{client.name}}" -->
                    <a [href]="client.url" target="_blank">
                        <img [src]="client.img" alt="team" />
                    </a>                    
                </div> 
            </ng-template>
        </owl-carousel-o>
    </div>
     
</section>

<div class="info-area pb-70 d-none">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i1.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-info"></i> About Us</h3>
                        <div class="arrow">
                            <a routerLink="/about"><i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i2.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-support"></i> Our Vision</h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-info si-30">
                    <div class="info-img">
                        <img src="assets/images/info/i3.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-goal"></i>Our Goal</h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="home-service-area pb-100" *ngIf="divisions && divisions.length > 0" id="divisions" >
    <div class="container">
        <div class="section-title">
            <h2>{{ 'Divisions' | translate }}</h2>
            <p>{{ 'Tailored tech solutions for all enterprise’s unique needs.' | translate }}</p>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12" *ngFor="let division of divisions">
                <div class="single-service">
                    <div class="service-img">
                        <a href="{{division.url}}" target="_blank">
                            <img [src]="division.img" alt="service" />
                        </a>
                    </div>
                    <div class="service-content">
                        <a href="{{division.url}}" title="{{division.title}}" target="_blank">
                            <h3>{{ division.title | translate }}</h3>
                        </a>
                        <p>{{ division.description | translate }}</p>
                        <a href="{{division.url}}" title="{{division.title}}" target="_blank" class="line-bnt">{{ 'Read More' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-process-area pt-100 pb-70" >
    <video autoplay loop [muted]="'muted'" playsinline src="assets/videos/office.mp4"></video>
    <div class="container">
        <div class="section-title">
            <span>{{ 'Working Process' | translate }}</span>
            <h2>{{ 'We are popular because of our way of working' | translate }}</h2>
            <p class="d-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>{{ 'Cloud-Based and Licensed Products' | translate }}</h3>
                        <p>{{ 'Providing cloud-based and licensed functional products & services tailored to the client’s specific needs.' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>{{ 'Implementation' | translate }}</h3>
                        <p>{{ 'We have a qualified team specialized in systems and technological implementation, working closely with our clients. With expertise, experience, and collaboration, our team ensures enhanced efficiency and effectiveness in enterprise functions.' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span class="d-none"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>{{ 'Client Support' | translate }}</h3>
                        <p>{{ 'Al Amthal provides comprehensive technical support to its clients, ensuring prompt resolution of issues and smooth operation of their software applications.' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 d-none">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Product Handover</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="pt-100"></div>

<section class="home2-choose-area " id="partners">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'Partners' | translate }}</h2>
            <p>{{ 'We are proud to be certified or partnering with the following:' | translate }}</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-2 col-sm-4" *ngFor="let partner of certificationPartners">
                <div class="single-choose"
                    [ngClass]="{
                        'link': partner.link
                    }"
                    (click)="partner.link ? clickPartner(partner.type, partner.header, partner.link) : null">
                    <span class="badge-partner"  [ngStyle]="{'background': partner.badgeName == 'Certification' ? '#f9b233' : '#309167' }" >{{ partner.badgeName | translate }}</span>
                    <div class="icon">
                        <img [src]="partner.logo" alt="{{ partner.header | translate }}">
                    </div>
                    <h3>{{ partner.header | translate }}</h3>
                </div>
            </div>  
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-2 col-sm-4" *ngFor="let partner of partnerPartners">
                <div class="single-choose"
                    [ngClass]="{
                        'link': partner.link
                    }"
                    (click)="partner.link ? clickPartner(partner.type, partner.header, partner.link) : null">
                    <span class="badge-partner"  [ngStyle]="{'background': partner.badgeName == 'Certification' ? '#f9b233' : '#309167' }" >{{ partner.badgeName | translate }}</span>
                    <div class="icon">
                        <img [src]="partner.logo" alt="{{ partner.header | translate }}">
                    </div>
                    <h3>{{ partner.header | translate }}</h3>
                </div>
            </div>  
        </div>
    </div>
</section>
<div class="pb-70"></div>

<section class="client-area ptb-100  bg-color" >
    <div class="container">
        <div class="section-title">
            <span class="d-none">Testimonials</span>
            <h2>{{ 'Testimonials' | translate }}</h2>
            <p>{{ 'Discover the success stories of our delighted clients!' | translate }}</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://cdn2.mallats.com/AmthalGroup/img/testimonials/YahyaAlMulla.jfif" alt="{{ 'Yahya AlMulla' | translate }}">
                        <p>{{ 'We required a system which would integrate all functions of our organization without much complications and with Arabic interface. With Optimum ERP we found exactly what we needed.' | translate }}</p>
                        <h3>{{ 'Yahya AlMulla' | translate }}</h3>
                        <span>{{'Asst. Under Secretary, Council of Representatives, Kingdom of Bahrain' | translate}}</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://cdn2.mallats.com/AmthalGroup/img/testimonials/NasserALBadi.jfif" alt="{{'Nasser AlBadi' | translate}}">
                        <p>{{'Only Optimum ERP could help us to consolidate all group companies’ financials with ease. We loved the user-friendly interface yet very powerful functionalities.' | translate }}</p>
                        <h3>{{'Nasser AlBadi' | translate}}</h3>
                        <span>{{'General Manager, AlBadi Investments Group, Oman' | translate}}</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://cdn2.mallats.com/AmthalGroup/img/testimonials/SalmanAbdullaN.png" alt="{{'Salman Abdulla' | translate }}">
                        <p>{{'Our successful journey with Al-Amthal started more than 10 years ago. The CrediMax mobile app is an example of their quality of work. The solutions we use cover our core business operations as well as back office functions.' | translate }}</p>
                        <h3>{{'Salman Abdulla' | translate }}</h3>
                        <span>{{'Head of IT, CrediMax, Bahrain' | translate}}</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/AFS.png" alt="{{'AFS' | translate}}">
                        <p>{{'Optimum has been a pillar of our operations for over a decade. The solution is uniquely capable of handling our complete back office operations along with WPS system which is used by our merchants and client banks.' | translate }}</p>
                        <h3>{{'AFS' | translate}}</h3>
                        <span class="d-none">Head of IT, CrediMax, Bahrain</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://cdn2.mallats.com/AmthalGroup/img/testimonials/ShakerAlhelli.jfif" alt="{{'Shaker AlHelli' | translate}}">
                        <p>{{'We have tried various HRMS & Attendance solutions in the past and failed to implement any of them successfully. With Mallats HRM & TAM our complex policies were easy to implement. It’s the perfect fit for retail environment.' | translate}}</p>
                        <h3>{{'Shaker AlHelli' | translate}}</h3>
                        <span>{{'CEO, AlHelli Supermarket' | translate }}</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://cdn2.mallats.com/AmthalGroup/img/testimonials/AlJazeera.jpg" alt="{{ 'Shyam Nair' | translate }}">
                        <p>{{'With Wolke mystaff, all our employee information lives in a single, secure database, with powerful reporting tools and editing capabilities at our fingertips. The added levels of accountability are fantastic.' | translate }}</p>
                        <h3>{{ 'Shyam Nair' | translate }}</h3>
                        <span>{{'Head of HR / Admin, Al Jazeera Exchange' | translate}}</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://cdn2.mallats.com/AmthalGroup/img/testimonials/energy-city.jpg" alt="{{'Azra Aziz' | translate }}">
                        <p>{{'With Wolke mystaff the transition from manual to automated processes was effortless and smooth. The support we have received from Al-Amthal team has been amazing.' | translate }}</p>
                        <h3>{{'Azra Aziz' | translate }}</h3>
                        <span>{{'Group HR Manager, Al Wessil Holding ( Former Energy city Qatar)' | translate }}</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://cdn2.mallats.com/AmthalGroup/new/banz.png" alt="{{'Nada Al Abdulla' | translate }}">
                        <p>{{'Your team has been incredibly supportive. We are very pleased with the outcome of your product & services, and the hard work of your team in providing wonderful service to Banz Group.' | translate }}</p>
                        <h3>{{'Nada Al Abdulla' | translate }}</h3>
                        <span>{{'Quality and Procurement Controller, Banz Group' | translate }}</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<section class="technology-area ptb-100  bg-color d-none" >
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Updated Technology</h2>
                    <p>You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with.</p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going There are many variations of passages of Lorem Ipsum available, but the.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>
<div class="pt-100"></div>
<section class="home-case" *ngIf="productTabs && productTabs.length > 0"   id="products">
    <div class="container" >
        <div class="section-title">
            <span class="d-none">Case Studies</span>
            <h2>{{ 'Products' | translate }}</h2>
            <p>{{ 'Tailored solutions for your specific business needs.' | translate }}</p>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <ng-container *ngFor="let tab of productTabs">
                    <li class="nav-tab" [ngClass]="{'active': currentTab === tab.id}" >
                        <span (click)="switchTab($event, tab.id)">
                        {{ tab.title | translate }}
                        </span>
                    </li>
                </ng-container>
                
            </ul>
            <div class="tabs-container">
                <ng-container *ngFor="let tab of productTabs">
                    <div class="pane" id="tab{{tab.id}}" *ngIf="currentTab === tab.id">
                        <div class="row case-list">
                            <div class="col-lg-4 col-sm-6" *ngFor="let product of tab.products">
                                <div class="single-case" [ngClass]="{
                                    'disabled': product.disabled
                                }">
                                    <div class="case-img">
                                        <a (click)="openLink(product.link)" title="{{product.title}}" class="link" target="_blank"><img [src]="product.img" alt="case"/></a>
                                    </div>
                                    <div class="content">
                                        <a (click)="openLink(product.link)" title="{{product.title}}" target="_blank" class="title-link link"><h3>{{ product.title | translate }}</h3></a>
                                        <div class="icons-container">
                                            <a title="{{product.title}}" [class.disabled]="product.youtube == null || product.youtube == ''" href="{{product.youtube}}" target="_blank"  class="icon-ancher" > <i class='bx bxl-youtube'></i>  </a>
                                            <!-- (click)="openPDF(product.title, product.pdf)" -->
                                            <a title="{{product.title}}" [class.disabled]="product.pdf == null || product.pdf == ''" href="{{product.pdf}}" target="_blank" class="icon-ancher" > <i class='bx bxs-file-pdf' ></i>  </a>
                                            <a title="{{product.title}}" [class.disabled]="product.website == null || product.website == ''" href="{{product.website}}" target="_blank" class="icon-ancher d-none" > <i class='bx bxl-chrome' ></i>  </a>
                                        </div>
                                        <p class="d-none">{{product.description}}</p>
                                        <a title="{{product.title}}" routerLink="/case-studies-details" class="line-bnt d-none">{{ 'View Project Details' | translate }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="case-btn text-center d-none">
            <p>We Have More Amazing Cases. <a routerLink="/" title="{{'View More' | translate }}">{{'View More' | translate }}</a></p>
        </div>
    </div>
</section>
<div class="pb-100"></div>
<section class="home-blog-area bg-color ptb-100" *ngIf="newsData">
    <div class="container">
        <div class="section-title">
            <span class="d-none">Blog Post</span>
            <h2>{{ 'Latest News' | translate }}</h2>
            <p>{{ 'Stay Informed with the Latest News!' | translate }}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let news of newsData">
                <div class="single-blog">
                    <div class="blog-img" [style.background-image]="getBackgroundImageUrl(news.value[0])">
                        <a routerLink="/news-details/{{ news.key }}" title="{{textService.removeHTMLTags(news.value[1])}}"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>{{ news.value[2] }}</li>
                            <li class="d-none"><a routerLink="/news">By Admin</a></li>
                        </ul>
                        <a routerLink="/news-details/{{ news.key }}" title="{{textService.removeHTMLTags(news.value[1])}}"><h3 class="blog-title" [innerHTML]="textService.removeHTMLTags(news.value[1])"></h3></a>
                        <p [innerHTML]="textService.getSummary(news.value[3], 200)"></p>
                        <a routerLink="/news-details/{{ news.key }}" title="{{textService.removeHTMLTags(news.value[1])}}" class="line-bnt">{{ 'Read More' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-btn text-center">
            <p>{{ 'We Have More Usefull Posts For You.' | translate }} <a routerLink="/news" title="{{ 'View More' | translate }}">{{ 'View More' | translate }}</a></p>
        </div>
    </div>
</section>

<div class="pt-100"></div>
<section class="home-team-area pb-100"  id="team" >
    <div class="container" >
        <div class="section-title">
            <span class="d-none">Team Members</span>
            <h2>{{ 'Team' | translate }}</h2>
            <p>{{ 'Introducing our team of experts and innovators.' | translate }}</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide *ngFor="let member of teams">
                    <div class="single-team">
                        <div class="team-img">
                            <img [src]="member.profile" alt="team" />
        
                            <!-- <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul> -->
                        </div>        
                        <div class="content text-center">
                            <h3>{{ member.name | translate }}</h3>
                            <p>{{ member.position | translate }}</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact ptb-100 bg-color d-none">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Reach Out and Connect</h2>
            <p class="d-none">It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="title" name="title" id="title" class="form-control" required placeholder="Your Title" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="comp_name" id="comp_name" class="form-control" required placeholder="Company Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <!-- <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.jpg" alt="contact" />
                </div> -->

                <div class="google-maps-container" *ngIf="apiLoaded | async">
                    <google-map
                        width="100%"
                        height="100%"
                        [center]="center"
                        [zoom]="zoom">
                        <map-marker *ngFor="let markerPosition of markerPositions"
                            [position]="markerPosition"
                            [options]="markerOptions"></map-marker>
                    </google-map>
                </div>
            </div>
        </div>
    </div>
</section>
