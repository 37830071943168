<footer class="footer-area"> <!-- pt-100-->
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="https://cdn2.mallats.com/AmthalGroup/new/logo.svg" alt="logo" /></a>
                    </div>
                    <p>{{ 'Al-Amthal Group is a dynamic and fast-growing company with multiple headquarters and regional offices in a number of Middle Eastern countries.' | translate }}</p>

                    <!-- <div class="subscribe">
                        <h4>Join Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="Your Email" name="EMAIL" required autocomplete="off">
                            <button class="box-btn" type="submit">Subscribe</button>
                        </form>
                    </div> -->

                    <ul class="social">
                        <li><a href="https://www.facebook.com/Amthal-Group-218394561513052/" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                        <li><a href="https://twitter.com/amthalgroup/" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ffffff}</style><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                        </a></li>
                        <li><a href="https://www.instagram.com/amthalgroup/" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        <li><a href="https://www.youtube.com/@amthalgroups" target="_blank"><i class='bx bxl-youtube' ></i></a></li>
                        <li><a href="https://www.linkedin.com/company/amthalgroup" target="_blank"><i class='bx bxl-linkedin' ></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-pinterest' ></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>{{ 'About Al-Amthal' | translate }}</h3>
                    <ul class="footer-list">
                        <li><a routerLink="/about">{{ 'Corporate Profile' | translate }}</a></li>
                        <li><a routerLink="/privacy-policy">{{ 'Privacy Policy' | translate }}</a></li>
                        <li><a routerLink="/" fragment="partners">{{ 'Partnerships' | translate }}</a></li>
                        <li><a href="https://portal.al-amthal.com/en/careers/CurrentVacancies.aspx" target="_blank">{{ 'Careers' | translate }}</a></li>
                        <li><a routerLink="/contact">{{ 'Contact Us' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content">
                    <h3>{{ 'Media' | translate }}</h3>
                    <ul class="footer-list">
                        <li><a routerLink="/news">{{ 'Latest News' | translate }}</a></li>
                        <li><a (click)="textService.openCorporateProfile()" target="_blank" class="link">{{ 'Corporate Brochure' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+1123456789"><i class="bx bx-mobile-alt"></i> +1 123 456 789</a></li>
                        <li><a href="tel:+1975456789"><i class="bx bx-phone"></i> +1 975 456 789</a></li>
                        <li><a href="mailto:hello@aiva.com"><i class="bx bxs-envelope"></i> hello@aiva.com</a></li>
                        <li><a href="mailto:support@aiva.com"><i class="bx bxs-envelope"></i> support@aiva.com</a></li>
                        <li><i class="bx bxs-map"></i> 28/A street, New York, USA</li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <!-- <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/solutions">Solutions</a></li>
                        <li><a routerLink="/case">Case Studies</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div> -->

                <div class="col-lg-12 text-center">
                    <p>
                        <span>{{ 'Made with' | translate }}</span>
                        &nbsp;
                        <svg title="SVG Heart Icon" viewBox="-11 -11 122 111" width="15" height="15">
                            <path d="
                                     M 50 0,
                                     A 1 1 0 1 0 0 50,
                                     L50,100,
                                     L100,50,
                                     A 1 1 0 1 0 50 0" fill="#ef3232" />
                        </svg>
                        &nbsp;
                        {{ 'by' | translate }}
                        &nbsp;
                        {{ 'Al Amthal Group' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>