<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'Search Engine Optimization' | translate }}</h2>
            <ul>
                <li><a routerLink="/">{{ 'Home' | translate }} </a></li>
                <li class="active">{{ 'Search Engine Optimization' | translate }}</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <p>{{'With Al-Amthal’s SEO, get more traffic to your website with versatile solutions for every step of your SEO journey.. These functions are provided under the following services:' | translate}}</p>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="feature-img">
                    <img class="rounded" src="https://system.al-amthal.com/AmthalV12/Upload/B2C/WebsiteAudit.jpg" alt="feature" />
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>{{'Site Audit' | translate}}</h2>
                        <p>{{'Spot issues on your site and get a full list of what you can do to fix them:' | translate}}</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> {{'Check for broken pages, links, and problems with basic html tags.' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Detect complex issues related to international SEO, crawlability, and schema optimization.' | translate}}</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>{{'Keyword Research' | translate}}</h2>
                        <p>{{'Navigate the most trusted keyword research database with tons of metrics and easy-to-use filters. Discover new targets based on:' | translate}}</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> {{'Competition level' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Keyword difficulty' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Monthly volume' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Related phrases' | translate}}</li>
                        <li><i class="flaticon-correct"></i> {{'Questions, and more.' | translate}}</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="feature-img">
                    <img class="rounded" src="https://system.al-amthal.com/AmthalV12/Upload/B2C/keywords.jpg" alt="feature" />
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="feature-img">
                    <img class="rounded" src="https://system.al-amthal.com/AmthalV12/Upload/B2C/LinkBuilding.jpg" alt="feature" />
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>{{'Link Building' | translate}}</h2>
                        <ul>
                            <li><i class="flaticon-correct"></i> {{'Run Backlink Gap analysis to find out who is linking to the other sites in your niche, but not yours.' | translate}}</li>
                            <li><i class="flaticon-correct"></i> {{'Analyze the data on a competitor’s backlinks with our Backlink Analytics reports.' | translate}}</li>
                            <li><i class="flaticon-correct"></i> {{'Set up an outreach campaign in the Link Building Tool. Email prospects directly from Semrush to build new links to your site.' | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>